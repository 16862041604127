<template lang="pug">
v-snackbar(
  v-model="isShown"
  app
  location="bottom right"
  :color="settings.color"
  :timeout="settings.timeout"
)
  v-icon(start :icon="icon")
  span {{ settings.message }}
  template(
    #actions
    v-if="settings.isCloseable"
  )
    v-btn(
      icon="$close"
      density="comfortable"
      @click="hide"
    )
</template>

<script setup lang="ts">
  import {
    mdiAlertOutline,
    mdiAlertCircleOutline,
    mdiCheckCircleOutline,
    mdiInformationOutline
  } from '@mdi/js'

  const { isShown, settings, hide } = useNotification()

  const icon = computed(() => {
    return {
      primary: mdiInformationOutline,
      info: mdiInformationOutline,
      success: mdiCheckCircleOutline,
      warning: mdiAlertOutline,
      error: mdiAlertCircleOutline
    }[settings.value.color]
  })
</script>
