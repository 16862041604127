<template lang="pug">
img(
  alt="Singa"
  src="~/assets/images/logo.svg"
  :style="{ 'width': '100%' }"
  :class="{ 'light-mode-logo': isLight }"
)
</template>

<script setup lang="ts">
  const { isLight } = useThemeToggle()
</script>

<style lang="scss">
  .light-mode-logo {
    filter: brightness(0%) saturate(100%) invert(0%) sepia(7%) saturate(7488%) hue-rotate(67deg) brightness(108%) contrast(97%);
  }
</style>
